import { Form } from "./Form";
import { Courses } from "./Courses";
import { StyledContent } from "./styles";
import { useEffect, useState } from "react";
import { Loading } from "components/Loading";
import { API_URL } from "environment";

const Content = () => {
  const [title, setTitle] = useState("Black Friday Exclusiva para Alunos");
  const [showContent, setShowContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [courseslist, setCoursesList] = useState<number[]>([]);
  const [email, setEmail] = useState<string>("");
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const emailParams = urlParams.get("email")?.replace(" ", "+");

  const formId = "11cd85a2-5fd8-42c0-b90d-2fe78cfd377b";

  const handleSubmitResponse = async (data: Response) => {
    const responseArray = await data.json();
    if (data.status === (404 || 422 || 451)) {
      setCoursesList([3, 1]);
      setShowContent(true);
    } else if (responseArray.data.length === 0) {
      setCoursesList([3, 1]);
      setShowContent(true);
    } else if (!!responseArray.data.length) {
      setCoursesList(responseArray.data);
      setShowContent(true);
    }
    setLoading(false);
  };

  const getProductsbyUser = async () => {
    setLoading(true);
    const response = await fetch(
      `${API_URL}products-ids-by-user-email-with-inactive-enrollments?email=${email}`,
      {
        method: "GET",
      }
    );
    return response;
  };

  useEffect(() => {
    if (emailParams) {
      const decodedEmail = decodeURIComponent(emailParams);
      const isValid = validarEmail(decodedEmail);
      if (isValid) {
        setEmail(decodedEmail);
      }
    }
  }, [emailParams]);

  useEffect(() => {
    courseslist.length === 0
      ? setTitle("Black Friday Exclusiva para Alunos")
      : courseslist.length === 4
      ? setTitle("Nenhuma oferta disponível")
      : setTitle("Ofertas disponíveis para você");
  }, [courseslist]);

  const validarEmail = (email: string) => {
    // Expressão regular para validar o formato do e-mail
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/;

    // Testa o e-mail usando a expressão regular
    return regexEmail.test(email);
  };

  useEffect(() => {
    const scriptMain = document.createElement("script");
    scriptMain.charset = "utf-8";
    scriptMain.type = "text/javascript";
    scriptMain.src = "//js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(scriptMain);
    scriptMain.addEventListener("load", () => {
      //@ts-ignore
      if (window.hbspt) {
        //@ts-ignore
        window.hbspt.forms.create({
          portalId: "42671822",
          formId: formId,
          target: "#hubspotForm",
          region: "na1",
        });
      }
    });
  }, []);

  useEffect(() => {
    if (email !== "") {
      getProductsbyUser().then((data) => handleSubmitResponse(data));
      const hubspotInput = document.getElementById(
        `email-${formId}`
      ) as HTMLFormElement;
      const hubspotForm = document.getElementById(
        `hsForm_${formId}`
      ) as HTMLFormElement;
      if (hubspotInput && hubspotForm) {
        hubspotInput.value = email;
      }
      if (hubspotForm) {
        hubspotForm.submit();
      }
    }
  }, [email]);

  return (
    <main>
      <div
        id="hubspotForm"
        style={{
          display: "none",
        }}
      ></div>
      <StyledContent>
        <div>
          <h1>
            <span>{title}</span>
          </h1>
        </div>
        {loading && <Loading />}
        {email && !loading && <Courses alredyHave={courseslist} />}
        {email === "" && (
          <Form
            handleSubmitResponse={handleSubmitResponse}
            setLoading={setLoading}
          />
        )}
      </StyledContent>
    </main>
  );
};

export { Content };
