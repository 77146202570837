import { styled } from "@mui/material/styles";

export const CourseGrid = styled("div")`
  display: flex;
  gap: 32px;
  margin-bottom: 60px;
  justify-content: center;
  flex-direction: column;
  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const CourseGridItem = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
