import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FormContainer = styled("div")`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 10px;
  border: 1px solid #707070;
  background: #161616;
  margin: 0 auto;
  margin-bottom: 64px;
  max-width: 780px;
  & > p {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const StyledForm = styled("form")`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  & > label {
    color: #fcca50;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
  }
  & > input {
    border-radius: 10px;
    border: 0.3px solid #707070;
    background: #303030;
    font-size: 14px;
    color: white;
    padding: 16px;
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 10px;
  background: #fcca50;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: none;
  width: 100%;
  padding: 16px;
  font-family: "Source Sans 3";
  :hover {
    background: #ffcd04;
  }
`;
