import { FormEvent, useEffect, useState } from "react";
import { FormContainer, StyledButton, StyledForm } from "./styles";
import { API_URL } from "environment";

interface FormProps {
  handleSubmitResponse: (data: Response) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Form = ({ handleSubmitResponse, setLoading }: FormProps) => {
  const [email, setEmail] = useState("");

  // const getProductsbyUser = async () => {
  //   setLoading(true);
  //   const response = await fetch(
  //     `${API_URL}products-ids-by-user-email-with-inactive-enrollments?email=${email}`,
  //     {
  //       method: "GET",
  //     }
  //   );
  //   return response;
  // };

  const handleSubmit = (e: FormEvent) => {
    let url = window.location.href;
    window.location.href = url + `?email=${email}`;
  };

  return (
    <FormContainer>
      <p>
        Insira o email que você utiliza na plataforma Full Cycle para ver as
        ofertas da Black Friday!
      </p>
      <StyledForm id="student" onSubmit={handleSubmit}>
        <label htmlFor="email">E-mail*</label>
        <input
          type="email"
          name="email"
          id="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </StyledForm>
      <StyledButton variant="contained" type="submit" form="student">
        Ver Ofertas
      </StyledButton>
    </FormContainer>
  );
};
