import { styled } from "@mui/material/styles";

export const StyledCard = styled("div")`
  border-radius: 10px;
  border: 1px solid #707070;
  background: #161616;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 24px;
  max-width: 312px;
  flex-grow: 1;
`;

export const CourseInformation = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
`;

export const CourseLogo = styled("div")`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
`;

export const FullPrice = styled("p")`
  color: #fff;
  text-align: center;
  font-family: "Source Sans 3", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;

  span {
    color: #fedda4;
    font-family: "Source Sans 3", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: line-through;
    text-decoration-thickness: 1px;
  }
`;

export const PriceWithDiscount = styled("p")`
  color: #fff;
  font-family: "Source Sans 3", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-align: center;

  span {
    color: #fedda4;
    text-align: center;
    font-family: "Source Sans 3", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const Installments = styled("p")`
  color: #fff;
  font-family: "Source Sans 3", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 0;

  span {
    color: #fedda4;
    font-family: "Source Sans 3", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const Text = styled("span")`
  color: #fff;
  text-align: center;
  font-family: "Source Sans 3", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

export const Link = styled("a")`
  border-radius: 10px;
  background: #fcca50;
  padding: 20px 80px;
  color: #000;
  text-align: center;
  font-family: "Source Sans 3", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-decoration: none;
  max-width: 264px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

export const PaymentMethodsList = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 19px;
  margin-top: 24px;
`;
