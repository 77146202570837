import {
  CourseInformation,
  CourseLogo,
  FullPrice,
  Installments,
  Link,
  PaymentMethodsList,
  PriceWithDiscount,
  StyledCard,
  Text,
} from "./styles";

import PixIcon from "assets/icons/pix.png";
import CreditCardIcon from "assets/icons/creditCard.png";
import BoletoIcon from "assets/icons/boleto.png";
import { CardProps } from "./types";

const Card = ({
  logo,
  fullPrice,
  priceWithDiscount,
  installments,
  installmentsQuantity,
  url,
}: CardProps) => {
  return (
    <StyledCard>
      <CourseLogo>
        <img src={logo} alt="Course Name" />
      </CourseLogo>
      <CourseInformation>
        <FullPrice>
          De <span>R$ {fullPrice}</span> por apenas
        </FullPrice>
        <PriceWithDiscount>
          <span>R$ {priceWithDiscount}</span> à vista
        </PriceWithDiscount>
        <Text>ou</Text>
        <Installments>
          {installmentsQuantity}x de <span>R$ {installments}</span>
        </Installments>
      </CourseInformation>
      <Link href={url} target="_blank">
        Saiba Mais
      </Link>
      <PaymentMethodsList>
        <img src={BoletoIcon} alt="Boleto" />
        <img src={PixIcon} alt="Pix" />
        <img src={CreditCardIcon} alt="Cartão de Crédito" />
      </PaymentMethodsList>
    </StyledCard>
  );
};

export { Card };
