import { styled } from "@mui/material/styles";

export const StyledContent = styled("div")`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 15px;
  & > div {
    & > h1 {
      color: #000;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
        margin-bottom: 24px;
      ${(props) => props.theme.breakpoints.up("lg")} {
        margin-bottom: 36px;
      }
      & > span {
        border-radius: 8px;
        background: #ffcd04;
        box-shadow: -8px 0px 0 5px #ffcd04, 8px 0px 0 5px #ffcd04,
          0 0 0 5px #ffcd04;
        box-decoration-break: clone;
      }
    }
  }
`;
