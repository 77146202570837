import { StyledFooter } from "./styles";
const Footer = () => {
  return (
    <StyledFooter>
      <p>© Full Cycle - Todos os direitos reservados</p>
    </StyledFooter>
  );
};

export { Footer };
