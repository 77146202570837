import { Content } from "../components/Content";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import "../index.css";
const Home = () => {
  return (
    <>
      <Header />
      <Content />
      <Footer />
    </>
  );
};

export { Home };
