import { styled } from "@mui/system";

export const HeaderContainer = styled("header")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 36px 20px 36px;
  & > img {
    width: 200px;
    height: 200px;
  }
  & > div {
    & > h1 {
      color: #000;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      & > span {
        border-radius: 8px;
        background: #ffcd04;
        box-shadow: -8px 0px 0 5px #ffcd04, 8px 0px 0 5px #ffcd04,
          0 0 0 5px #ffcd04;
        box-decoration-break: clone;
      }
    }
  }
`;
