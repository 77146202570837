import { styled } from "@mui/material/styles";

export const StyledFooter = styled("footer")`
  border-top: 1px solid #707070;
  background: #161616;
  display: flex;
  justify-content: center;
  padding: 36px 15px;

  & > p {
    color: #fff;
    font-family: Source Sans 3, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin: 0;
    text-align: center;

    @media screen and (min-width: 960px) {
      font-size: 16px;
    }
  }
`;
