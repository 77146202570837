import { Card } from "./Card";
import PosGoLogo from "../../../assets/logos/posGo.png";
import { CourseGrid, CourseGridItem } from "./styles";
import * as environment from "environment";
import { coursesData } from "./Card/data";

interface CoursesProps {
  alredyHave: number[];
}
const Courses = ({ alredyHave }: CoursesProps) => {
  if (alredyHave.includes(2)) {
    const indexOfCourseToModify = coursesData.findIndex(
      (course) => course.id === 4
    );

    if (indexOfCourseToModify !== -1) {
      coursesData[indexOfCourseToModify] = {
        id: 4,
        logo: PosGoLogo,
        fullPrice: environment.PosGoFullPriceStudent,
        priceWithDiscount: environment.PosGoPriceWithDiscountStudent,
        installments: environment.PosGoInstallmentsStudent,
        installmentsQuantity: environment.PosGoInstallmentsQtyStudent,
        url: environment.PosGoCartLinkStudent,
      };
    }
  }

  return (
    <CourseGrid>
      {coursesData.map((data, index) => {
        if (!alredyHave.includes(data.id)) {
          return (
            <CourseGridItem key={index}>
              <Card {...data} />
            </CourseGridItem>
          );
        }
      })}
    </CourseGrid>
  );
};

export { Courses };
