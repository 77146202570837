import { HeaderContainer } from "./styles";
import BlackFridayLogo from "assets/logos/blackFriday.png";
const Header = () => {
  return (
    <HeaderContainer>
      <img src={BlackFridayLogo} alt="" />
    </HeaderContainer>
  );
};

export { Header };
