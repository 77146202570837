import * as environment from "environment";
import MBALogo from "assets/logos/mbaFullCycle.png";
import FullCycleLogo from "assets/logos/fullCycle3.png";
import PosGoLogo from "assets/logos/posGo.png";
import { CardProps } from "./types";

export const coursesData: CardProps[] = [
  {
    id: 3,
    logo: MBALogo,
    fullPrice: environment.MBAFullPrice,
    priceWithDiscount: environment.MBAPriceWithDiscount,
    installments: environment.MBAInstallments,
    installmentsQuantity: environment.MBAInstallmentsQty,
    url: environment.MBACartLink,
  },
  {
    id: 1,
    logo: FullCycleLogo,
    fullPrice: environment.fcFullPrice,
    priceWithDiscount: environment.fcPriceWithDiscount,
    installments: environment.fcInstallments,
    installmentsQuantity: environment.fcInstallmentsQty,
    url: environment.fcCartLink,
  },
  {
    id: 4,
    logo: PosGoLogo,
    fullPrice: environment.PosGoFullPrice,
    priceWithDiscount: environment.PosGoPriceWithDiscount,
    installments: environment.PosGoInstallments,
    installmentsQuantity: environment.PosGoInstallmentsQty,
    url: environment.PosGoCartLink,
  },
];
